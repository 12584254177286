import { environment } from 'src/environments/environment';

export interface APIDataModel {
    version: string;
    apiEndPoint?: string;
    url?: string;
    host?: string;
    type?: string;
}

export const API_END_POINTS = {
    GetAllByUsers: {
        type: 'AttendanceManagement',
        version: '1',
        apiEndPoint: 'Attendance/allbyuser',
        host: environment.attendanceManagementServiceHOst,
    },
    GetAttendanceInDateRanges: {
        type: 'AttendanceManagement',
        version: '1',
        apiEndPoint: 'StaffAttendance/GetStaffAttendanceByDateRange',
        host: environment.attendanceManagementServiceHOst,
    },
    ChangeAttendance: {
        type: 'AttendanceManagement',
        version: '1',
        apiEndPoint: 'Attendance/changeattendance',
        host: environment.attendanceManagementServiceHOst,
    },
    DeleteAttendance: {
        type: 'AttendanceManagement',
        version: '1',
        apiEndPoint: 'Attendance/delete',
        host: environment.attendanceManagementServiceHOst,
    },
    // User
    GenerateOtp: {
        type: 'UserManagement',
        version: '1',
        apiEndPoint: 'OTP/GenerateOTP',
        host: environment.userMgmntServiceHost,
    },
    ValidateOtp: {
        type: 'UserManagement',
        version: '1',
        apiEndPoint: 'OTP/ValidateOTP',
        host: environment.userMgmntServiceHost,
    },

    GetAllEquipmentsByVenue: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueEquipment/all',
        host: environment.venueServiceHost,
    },
    GetAllEquipmentMasterData: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'EquipmentMasterData/all',
        host: environment.venueServiceHost,
    },
    AddVenueEquipment: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueEquipment/InsertUpdate',
        host: environment.venueServiceHost,
    },
    DeleteVenueEquipment: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueEquipment/Delete',
        host: environment.venueServiceHost,
    },
    GetAllVenues: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueBaseProfile/all',
        host: environment.venueServiceHost,
    },

    GetAllVenuesLazily: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueBaseProfile/Search',
        host: environment.venueServiceHost,
    },

    GetVenueById: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueBaseProfile/getbyid?id=',
        host: environment.venueServiceHost,
    },
    GetAllVenuesByUser: {
        type: 'VenueManagement',
        version: '1',
        apiEndPoint: 'VenueBaseProfile/getbyowner',
        host: environment.venueServiceHost,
    },

    GetAllVenuesByUserId: {
        type: 'UserManagement',
        version: '1',
        apiEndPoint: 'User/GetVenueByUserId',
        host: environment.userMgmntServiceHost,
    },

    DeleteVenue: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueBaseProfile/Delete',
        host: environment.venueServiceHost,
    },

    AddVenueDetails: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueBaseProfile/InsertUpdate',
        host: environment.venueServiceHost,
    },
    AddCommissionDetails: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'CommissionRate/InsertUpdate',
        host: environment.venueServiceHost,
    },
    DeleteCommission: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'CommissionRate/Delete',
        host: environment.venueServiceHost,
    },
    GetAllCommissions: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'CommissionRate/all',
        host: environment.venueServiceHost,
    },

    GetAllRoles: {
        type: 'userMangement',
        version: '1',
        apiEndPoint: 'Role/all',
        host: environment.userMgmntServiceHost,
    },

    AddVenueOwner: {
        type: 'userMangement',
        version: '1',
        apiEndPoint: 'User/add',
        host: environment.userMgmntServiceHost,
    },

    GetAllVenueOwners: {
        type: 'userMangement',
        version: '1',
        apiEndPoint: 'User/all/role',
        host: environment.userMgmntServiceHost,
    },

    // Services ADD Venue
    AddService: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'Service/InsertUpdate',
        host: environment.venueServiceHost,
    },
    DeleteService: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'Service/Delete',
        host: environment.venueServiceHost,
    },
    GetServicesMasterData: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'ServicesMasterData/all',
        host: environment.venueServiceHost,
    },
    GetServicesOfferedDataByVenue: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'Service/all',
        host: environment.venueServiceHost,
    },
    GetGalleryList: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'fileHandler/fetch/type',
        host: environment.fileMgmtServiceHost,
    },
    GetGalleryDetailsById: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'FileRecordDetails/getbyid',
        host: environment.venueServiceHost,
    },
    AddGallery: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'FileRecordDetails/CreateUpdateFileRecordDetail',
        host: environment.venueServiceHost,
    },
    DeleteGallery: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'fileHandler/',
        host: environment.fileMgmtServiceHost,
    },
    FileUpload: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'fileHandler/Upload',
        host: environment.fileMgmtServiceHost,
    },
    GetVenueCategoryMasterData: {
        type: 'venueMangement',
        version: '1',
        apiEndPoint: 'VenueCategoryMasterData/all',
        host: environment.venueServiceHost,
    },
    // User Management
    GetAllCustomers: {
        type: 'userMangement',
        version: '1',
        apiEndPoint: 'CustomerProfile/GetAllCustomers',
        host: environment.customerMgmtServiceHost,
    },
    GetAllPartners: {
        type: 'userMangement',
        version: '1',
        apiEndPoint: '',
        host: environment.venueServiceHost,
    },
    GetAllFitZoneUsers: {
        type: 'userMangement',
        version: '1',
        apiEndPoint: '',
        host: environment.venueServiceHost,
    },

    PosterImageWebUpload: {
        type: 'contentLibraryManagement',
        version: '1',
        apiEndPoint: 'filehandler/leaderboardupload',
        host: environment.contentLibraryServiceHost,
    },
    AddContentLibrary: {
        type: 'contentLibraryManagement',
        version: '1',
        apiEndPoint: 'filehandler/leaderboardupload',
        host: environment.contentLibraryServiceHost,
    },
    GetAllLibraries: {
        type: 'contentLibraryManagement',
        version: '1',
        apiEndPoint: 'filehandler/GetLeaderBoardFile',
        host: environment.contentLibraryServiceHost,
    },

    //Order and Payment Management
    GetAllOrdersList: {
        type: 'orderMangement',
        version: '1',
        apiEndPoint: 'order/search?',
        host: environment.orderMgmtServiceHost,
    },
    GetOrderById: {
        type: 'orderMangement',
        version: '1',
        apiEndPoint: 'order/',
        host: environment.orderMgmtServiceHost,
    },
    DownloadInvoice: {
        type: 'orderMangement',
        version: '1',
        apiEndPoint: 'order/invoice/',
        host: environment.orderMgmtServiceHost,
    },
    SendEmail: {
        type: 'orderMangement',
        version: '1',
        apiEndPoint: 'order/invoice/email/',
        host: environment.orderMgmtServiceHost,
    },
    Dashboard: {
        type: 'dashboard',
        version: '1',
        apiEndPoint: 'api/Metabase/',
        host: environment.reportManagementServiceHost,
    },
    //workout management
    WorkoutLevels: {
        type: 'workoutMangement',
        version: '1',
        apiEndPoint: 'WorkOutPlaner/',
        host: environment.workoutMgmtServiceHost,
    },
    DownloadWorkoutplan: {
        type: 'workoutMangement',
        version: '1',
        apiEndPoint: 'WorkOutPlaner/',
        host: environment.workoutMgmtServiceHost,
    },

    //targets
    GetAllTargetList: {
        type: 'TargetManagement',
        version: '1',
        apiEndPoint: 'Targets/GetAllTargets',
        host: environment.targetManagementService,
    },

    GetTargetById: {
        type: 'TargetManagement',
        version: '1',
        apiEndPoint: 'Targets/GetTargetById',
        host: environment.targetManagementService,
    },

    AddTarget: {
        type: 'TargetManagement',
        version: '1',
        apiEndPoint: 'Targets/add',
        host: environment.targetManagementService,
    },
    DeleteTarget: {
        type: 'TargetManagement',
        version: '1',
        apiEndPoint: 'Targets/',
        host: environment.targetManagementService,
    },
    UpdateTarget: {
        type: 'TargetManagement',
        version: '1',
        apiEndPoint: 'Targets/update',
        host: environment.targetManagementService,
    },
    //customer Management
    GetAllCustomersList: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: 'CustomerProfile/search?',
        host: environment.customerMgmtServiceHost,
    },

    GetSessionCount: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: 'query/sessionscount?',
        host: environment.sessionServiceHost,
    },
    GetAllTicket: {
        type: 'helpDesk',
        version: '1',
        apiEndPoint: 'helpdesk/',
        host: environment.helpDeskServiceHost,
    },
    GetAllCustomersCheckinList: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: 'Attendance/search?',
        host: environment.attendanceServiceHost,
    },
    AddCustomer: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: 'CustomerProfile/add',
        host: environment.customerMgmtServiceHost,
    },
    UpdateCustomer: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: 'CustomerProfile/update',
        host: environment.customerMgmtServiceHost,
    },
    GetCustomerAssessment: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: '/FitnessAssessment/GetFitnessAssessment?UserId=',
        host: environment.fitnessAssessmentServiceHost,
    },
    GetCustomerById: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: 'CustomerProfile/allbyuser',
        host: environment.customerMgmtServiceHost,
    },
    DeleteCustomer: {
        type: 'customerMangement',
        version: '1',
        apiEndPoint: '/CustomerProfile',
        host: environment.customerMgmtServiceHost,
    },
    GetAllSubscriptionRecord: {
        type: 'subscriptionRecordMangement',
        version: '1',
        apiEndPoint: 'SubscriptionRecord/Customer/',
        host: environment.subscriptionRecordServiceHost,
    },
    GetAllSubscription: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Subscription/search',
        host: environment.subscriptionMgmtServiceHost,
    },
    GetSubscriptionById: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Subscription/',
        host: environment.subscriptionMgmtServiceHost,
    },
    GetSubscriptionSearchById: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Subscription/searchById',
        host: environment.subscriptionMgmtServiceHost,
    },
    AddSubscription: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Subscription/add',
        host: environment.subscriptionMgmtServiceHost,
    },
    UpdateSubscription: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Subscription/update',
        host: environment.subscriptionMgmtServiceHost,
    },
    DeleteSubscription: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Subscription/',
        host: environment.subscriptionMgmtServiceHost,
    },
    PauseSubscription: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'SubscriptionRecord/',
        host: environment.subscriptionMgmtServiceHost,
    },
    GetAllGenericOffer: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Offer/search',
        host: environment.subscriptionMgmtServiceHost,
    },
    GetGenericOfferById: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Offer/GetGenericOfferById/',
        host: environment.subscriptionMgmtServiceHost,
    },
    AddGenericOffer: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Offer/add',
        host: environment.subscriptionMgmtServiceHost,
    },
    UpdateGenericOffer: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Offer/update',
        host: environment.subscriptionMgmtServiceHost,
    },
    DeleteGenericOffer: {
        type: 'subscriptionMangement',
        version: '1',
        apiEndPoint: 'Offer/',
        host: environment.subscriptionMgmtServiceHost,
    },
    GetOfflineVenueLeads: {
        type: 'VenueLeadManagement',
        version: '1',
        apiEndPoint: 'OfflineVenueLead/search',
        host: environment.venueLeadServiceHost,
    },
    AddOfflineVenueLeads: {
        type: 'VenueLeadManagement',
        version: '1',
        apiEndPoint: 'OfflineVenueLead/add',
        host: environment.venueLeadServiceHost,
    },
    UpdateOfflineVenueLeads: {
        type: 'VenueLeadManagement',
        version: '1',
        apiEndPoint: 'OfflineVenueLead/update',
        host: environment.venueLeadServiceHost,
    },
    GetAllStaff: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/GetAllStaff',
        host: environment.venueStaffManagementServiceHost,
    },
    GetAllStaffLazily: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/search?',
        host: environment.venueStaffManagementServiceHost,
    },

    GetVenueStaffById: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/GetStaffById',
        host: environment.venueStaffManagementServiceHost,
    },
    AddVenueStaff: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/add',
        host: environment.venueStaffManagementServiceHost,
    },
    GetStaffBySearch: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/search',
        host: environment.venueStaffManagementServiceHost,
    },

    UpdateVenueStaff: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/update?Id=',
        host: environment.venueStaffManagementServiceHost,
    },
    GetStaffProfile: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'filehandler/fetch/type',
        host: environment.fileMgmtServiceHost,
    },
    GetStaffGallery: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'filehandler/download',
        host: environment.fileMgmtServiceHost,
    },
    UploadStaffFiles: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'filehandler/upload',
        host: environment.fileMgmtServiceHost,
    },
    GetStaffFiles: {
        type: 'StaffManagement',
        version: '1',
        apiEndPoint: 'VenueStaff/DownloadFile',
        host: environment.venueStaffManagementServiceHost,
    },

    //users Management
    GetAllUsersList: {
        type: 'UserMangement',
        version: '1',
        apiEndPoint: 'User/search?',
        host: environment.userMgmntServiceHost,
    },
    AddUser: {
        type: 'UserMangement',
        version: '1',
        apiEndPoint: 'User/add',
        host: environment.userMgmntServiceHost,
    },
    UpdateUser: {
        type: 'UserMangement',
        version: '1',
        apiEndPoint: 'User/update',
        host: environment.userMgmntServiceHost,
    },
    GetUserById: {
        type: 'UserMangement',
        version: '1',
        apiEndPoint: 'User',
        host: environment.userMgmntServiceHost,
    },
    DeleteUser: {
        type: 'UserMangement',
        version: '1',
        apiEndPoint: 'User',
        host: environment.userMgmntServiceHost,
    },

    //users Management
    GetAllRewardsList: {
        type: 'RewardsManagement',
        version: '1',
        apiEndPoint: 'rewardconfig/all',
        host: environment.rewardsManagementServiceHost,
    },
    AddReward: {
        type: 'RewardsManagement',
        version: '1',
        apiEndPoint: 'rewardconfig/add',
        host: environment.rewardsManagementServiceHost,
    },
    UpdateReward: {
        type: 'RewardsManagement',
        version: '1',
        apiEndPoint: 'rewardconfig/update',
        host: environment.rewardsManagementServiceHost,
    },
    GetRewardById: {
        type: 'RewardsManagement',
        version: '1',
        apiEndPoint: 'rewardconfig',
        host: environment.rewardsManagementServiceHost,
    },
    DeleteReward: {
        type: 'RewardsManagement',
        version: '1',
        apiEndPoint: 'rewardconfig/delete',
        host: environment.rewardsManagementServiceHost,
    },

    GetAllGxSchedule: {
        type: 'GXPlanner',
        version: '1',
        apiEndPoint: 'gxsession/search?',
        host: environment.gxPlannerManagementServiceHOst,
    },
    GetGxScheduleById: {
        type: 'GXPlanner',
        version: '1',
        apiEndPoint: 'GXSchedule/GetGxScheduleById',
        host: environment.gxPlannerManagementServiceHOst,
    },
    AddSchedule: {
        type: 'GXPlanner',
        version: '1',
        apiEndPoint: 'GXSchedule/add',
        host: environment.gxPlannerManagementServiceHOst,
    },
    UpdateSchedule: {
        type: 'GXPlanner',
        version: '1',
        apiEndPoint: 'GXSchedule/update',
        host: environment.gxPlannerManagementServiceHOst,
    },
    DeleteSchedule: {
        type: 'GXPlanner',
        version: '1',
        apiEndPoint: 'GXSchedule/delete',
        host: environment.gxPlannerManagementServiceHOst,
    },

    GetServiceType: {
        type: 'VenueManagement',
        version: '1',
        apiEndPoint: 'ServicesMasterData/all',
        host: environment.venueManagementServiceHOst,
    },
};
